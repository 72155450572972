import React, { Component } from "react"
import "url"
import { Link } from "gatsby"
import InnerForm from "./InnerForm"
// import ErrorMessage from "./ErrorMessage"
import Reveal from "react-reveal/Reveal"

import styles from "./styles.module.less"

const forms = ["winback"]
const labels = ["winback"]

export default class WinBack extends Component {
  state = {
    form: "",
    message: "",
    errors: { message: "", form: "" },
    complete: false,
    failure: "",
  }
  handleSelect = e => {
    let form = e.target.value
    if (!forms.includes(form)) form = forms[0]
    this.setState({ form: e.target.value })
  }
  handleChange = e => {
    const {
      target: { name, value },
    } = e
    this.setState({ [name]: value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.isValid()) return

    const { message, form } = this.state
    const body = new URLSearchParams()
    body.append("form-name", form)

    body.append("message", message)

    fetch("/win-back/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body,
    })
      .then(() => {
        this.setState({ complete: true })
      })
      .catch(err => {
        this.setState({ complete: false, failure: err.message })
      })
  }

  isValid() {
    const { message, form } = this.state
    let isValid = true
    let errors = { message: "", form: "" }
    // let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    // if (!form) {
    //   errors.form = "Please make a selection"
    // }

    if (!message) {
      errors.message = "Enter a message"
      isValid = false
    }
    this.setState({ errors })
    return isValid
  }

  render() {
    const { form, failure, complete, errors } = this.state
    const hideSelect = failure || complete
    return (
      <section className={styles.Contact}>
        <Reveal>
          <div className={styles.inner}>
            <div className={styles.title}>
              {!this.state.complete && <h1>You are unsubscribed.</h1>}
            </div>
            <h3>
              Curious: How could we be more relevant to you and your world?
            </h3>

            <div>
              <form
                name={form}
                className={styles.contactForm}
                method="POST"
                data-netlify="true"
                onSubmit={this.handleSubmit}
              >
                <input type="hidden" name="form-name" value={form} />
                <InnerForm handleChange={this.handleChange} {...this.state} />
              </form>
            </div>
          </div>
        </Reveal>
      </section>
    )
  }
}
