import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import WinBack from "../components/WinBack"

const WinBackPage = () => (
  <Layout>
    <SEO
      title="Thesis | Unsubscribe confirmation"
      description="You’ve unsubscribed from the Thesis mailing list. Tell us what would make you stick around."
    />
    <WinBack />
  </Layout>
)

export default WinBackPage
